var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('scabe',{staticClass:"mx-2 my-3 border-b border-black",attrs:{"name":_vm.me.name}}),_vm._m(0),_c('div',_vm._g({},(_vm.Vid = _vm.vendedirId))),_c('div',{staticClass:"flex items-center justify-center my-4 mx-2 z-0"},[_c('table',{staticClass:"table-auto"},[_vm._m(1),_c('tbody',_vm._l((_vm.PedidoVendedorEspera),function(pedido,k){return _c('tr',{key:k},[_c('td',{staticClass:"border border-black px-1 text-center"},[_c('router-link',{staticClass:"text-blue-500 underline",attrs:{"to":{
								name: 'Vpedido',
								params: {
									id: pedido.id,
									name: _vm.me.name,
									hora: pedido.tiempo_estimado
								}
							}}},[_vm._v(_vm._s(pedido.id.padStart(4, 0)))])],1),_c('td',{staticClass:"border border-black px-1 py-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(pedido.estatus),expression:"pedido.estatus"}],staticClass:"focus:outline-none bg-white",attrs:{"name":"estado","id":"estado"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(pedido, "estatus", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"espera"}},[_vm._v("Espera")]),_c('option',{attrs:{"value":"ejecucion"}},[_vm._v("Ejecucion")]),_c('option',{attrs:{"value":"comprado"}},[_vm._v("Comprado")]),_c('option',{attrs:{"value":"envia"}},[_vm._v("En via")]),_c('option',{attrs:{"value":"completado"}},[_vm._v("Completado")])])]),_c('td',{staticClass:"border border-black py-2 text-center"},[_vm._v(" "+_vm._s(pedido.tiempo_estimado)+" ")])])}),0)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-start z-0 w-3/5 border-b border-black"},[_c('p',{staticClass:"flex justify-start font-bold mt-2 ml-2"},[_vm._v(" Lista de pedidos pendientes: ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"border border-black p-1"},[_vm._v("Orden de compra")]),_c('th',{staticClass:"border border-black p-1"},[_vm._v("Estado")]),_c('th',{staticClass:"border border-black p-1"},[_vm._v("Hora de entrega")])])])}]

export { render, staticRenderFns }