<template>
	<div>
		<scabe class="mx-2 my-3 border-b border-black" :name="me.name" />
		<div
			class="flex items-center justify-start z-0 w-3/5 border-b border-black"
		>
			<p class="flex justify-start font-bold mt-2 ml-2">
				Lista de pedidos pendientes:
			</p>
		</div>
		<div v-on="(Vid = vendedirId)"></div>
		<div class="flex items-center justify-center my-4 mx-2 z-0">
			<table class="table-auto">
				<thead>
					<tr>
						<th class="border border-black p-1">Orden de compra</th>
						<th class="border border-black p-1">Estado</th>
						<th class="border border-black p-1">Hora de entrega</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(pedido, k) in PedidoVendedorEspera" :key="k">
						<td class="border border-black px-1 text-center">
							<router-link
								class="text-blue-500 underline"
								:to="{
									name: 'Vpedido',
									params: {
										id: pedido.id,
										name: me.name,
										hora: pedido.tiempo_estimado
									}
								}"
								>{{ pedido.id.padStart(4, 0) }}</router-link
							>
						</td>
						<td class="border border-black px-1 py-2">
							<select
								name="estado"
								id="estado"
								class="focus:outline-none bg-white"
								v-model="pedido.estatus"
							>
								<option value="espera">Espera</option>
								<option value="ejecucion">Ejecucion</option>
								<option value="comprado">Comprado</option>
								<option value="envia">En via</option>
								<option value="completado">Completado</option>
							</select>
						</td>
						<td class="border border-black py-2 text-center">
							{{ pedido.tiempo_estimado }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
import scabe from "@/components/subCabeceraV.vue";
import gql from "graphql-tag";
export default {
	components: {
		scabe
	},
	data() {
		return {
			me: [],
			Vid: "",
			pedidos: [
				{
					id: "15",
					producto: "Queso",
					cantidad: "10",
					unidad: "kg",
					comentario: "algun comentario del gerente",
					estado: "espera",
					hora: "2:00"
				},
				{
					id: "16",
					producto: "jamon",
					cantidad: "30",
					unidad: "unidades",
					comentario: "algun comentario del gerente",
					estado: "espera",
					hora: "2:30"
				}
			]
		};
	},
	mounted() {
		this.$store.state.isLoading = true;
		this.$apollo
			.query({
				query: gql`
					query me {
						me {
							name
							id
							vendedores {
								id
								disponible
							}
						}
					}
				`
			})
			.then(data => {
				this.$store.state.isLoading = false;
				this.me = data.data.me;
			})
			.catch(error => {
				this.$store.state.isLoading = false;
				console.log(error);
			});
	},
	apollo: {
		PedidoVendedorEspera: {
			query: gql`
				query PedidoVendedorEspera($id: ID!) {
					PedidoVendedorEspera(vendedor_id: $id) {
						id
						estatus
						vendedor_id
						tiempo_estimado
					}
				}
			`,
			variables() {
				return {
					id: this.Vid
				};
			},
			fetchPolicy: "cache-and-network",
			update: data => data.PedidoVendedorEspera,
			error: (error, vm) => (vm.feedback.errorSearched = true),
			watchLoading(isLoading, countModifier) {
				// isLoading is a boolean
				// countModifier is either 1 or -1
				this.$store.state.isLoading = isLoading;
			}
		}
	},
	computed: {
		vendedirId() {
			var Vid = "";
			Vid = this.me.vendedores[0].id;
			return Vid;
		}
	}
};
</script>
